<template>
    <div class="ft-frame">
        <div class="header">
            <div class="logo"></div>
            <div class="nav">
              <template v-if="!hideContent" >
                <div v-for="(item) in menus" :key="item.path" class="nav-but" :class="{active : item.path === currentTopMenuId}" @click="goto(item, true)">{{ item.title }}</div>
              </template>
            </div>
            <div class="tools" v-if="userInfo">
                <div class="tools-btn" >
                  <span class="company-name" ><span style="color: #3BFF7B">●</span> {{ mediationTitle }} </span>
                </div>
                <div class="tools-btn">
                  <img
                    class="avater"
                    :src="userInfo.headImg || defaultAvater"
                  />
                </div>
                <div class="tools-btn">
                    <el-popover
                        placement="bottom"
                        popper-class="frame-popup-menu"
                        width="200"
                        trigger="hover">
                        <div class="top-menus">
                          <div class="frame-popup-menu-info">
                            <div class="avater">
                               <img :src="userInfo.headImg || defaultAvater"/>
                            </div>
                            <div class="name">
                              {{ userInfo.name }}
                            </div>
                            <div class="company">
                              {{ userInfo.mediationTitle || '-' }}
                            </div>
                          </div>
                          <div class="frame-popup-menu-item"  @click="$router.push('/system/account/info')">
                            <i class="iconfont icon-gerenxinxiicon" style="font-size: 16px;"/>
                            <span>个人信息</span>
                          </div>
                          <div class="frame-popup-menu-item" @click="logout">
                            <i class="iconfont icon-tuichuicon"/>
                            <span>退出</span>
                          </div>
                        </div>
                        <span
                          slot="reference"
                          style="
                            display: block;
                            text-align: right;
                            cursor: pointer;
                            white-space: nowrap;
                            height: 50px;
                          "
                        >
                          <span
                            style="
                              vertical-align: middle;
                              display: inline-block;
                              white-space: nowrap;
                              overflow: hidden;
                              max-width: 80px;
                              text-overflow: ellipsis;
                            "
                          >
                            {{ userInfo.name }}
                          </span>
                          <i
                            style="vertical-align: middle; margin-left: 10px"
                            class="el-icon-caret-bottom"
                          ></i>
                        </span>
                    </el-popover>
                </div>
            </div>
        </div>
        <div class="main">
            <div v-if="showMenu && !hideContent" class="menus">
              <el-scrollbar style="height:100%" class="hx">
              <ul>
                <li v-for="item in submenus" :key="item.path">
                    <div class="menu-item" @click="goto(item)" :class="{ active: currentMenuPath === item.path}">
                        <i :class="item.icon" class="menu-item-icon" :style="{ color: item.iconColor }"></i>
                        <label class="label">{{ item.title }}</label>
                        <i v-if="item.children && item.children.length > 0" @click="item.expand = !item.expand" :class="{ expand :item.children && item.children.length > 0, 'el-icon-arrow-down': item.expand, 'el-icon-arrow-right': !item.expand }"></i>
                    </div>
                    <el-collapse-transition>
                    <ul v-if="item.children && item.children.length > 0" v-show="item.expand">
                       <li v-for="subitem in item.children" :key="subitem.path">
                            <div class="menu-item" @click="goto(subitem)" :class="{ active: currentMenuPath === subitem.path}">
                                <i class="icon"></i>
                                <label class="label">{{ subitem.title }}</label>
                                <i class="expand"></i>
                                <template v-if="subitem.badge">
                                  <span class="badge" :class="{ zero: !badge[subitem.badge] }">{{ badge[subitem.badge] || '0' }}</span>
                                </template>
                            </div>
                       </li>
                    </ul>
                    </el-collapse-transition>
                </li>
              </ul>
              </el-scrollbar>
            </div>
            <div class="content" :style="{ padding: !showMenu || hideContent ? '0px' : '20px' } ">
                <div class="inside">
                  <router-view />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import defaultAvater from '@/assets/images/avater.png'
import routerMain from '@/router/router.main.js'
import MainService from '@/service/main.js'
const { routerList } = routerMain
export default {
  data () {
    return {
      showMenu: true,
      badge: {
        apply: 0, // 申请列晴
        myApply: 0 // 我的受理
      },
      hideContent: false,
      userInfo: null,
      defaultAvater,
      menus: [],
      mediationTitle: '',
      currentMenuPath: window.location.pathname
    }
  },
  beforeCreate () {
    this.$router.beforeEach(async (to, form, next) => {
      this.currentMenuPath = to.path
      if (to.fullPath.split('/')[1] === 'home') {
        this.updateBadge()
      }
      next()
    })
    this.$root.$on('ft-fullsceen-event', (value) => {
      this.showMenu = !value
    })
    this.$root.$on('ft-hide-frame-content', (value) => {
      this.hideContent = value
    })
    this.$root.$on('update-badge', () => {
      this.updateBadge()
    })
    this.$root.$on('update-user-info', () => {
      this.loadUserInfo()
    })
  },
  async created () {
    this.userInfo = null
  },
  async mounted () {
    this.loadUserInfo()
    this.updateBadge()
    this.menus = MainService.getMenus(routerList, '', (code) => {
      return this.hasPermission(code)
    })
    if (this.$route.path === '/') {
      this.$router.replace(this.findFirstMenuPath(this.menus))
    }
  },
  computed: {
    flatMenus () {
      const flattenTree = (data) => data.reduce((pre, item) => [...pre, item, ...flattenTree(item.children)], [])
      const result = flattenTree(this.menus || [])
      return result
    },
    currentMenuId () {
      const menu = this.flatMenus.find(row => row.path === this.currentMenuPath)
      return menu ? menu.path : null
    },
    currentTopMenuId () {
      const path = this.currentMenuPath
        .split('?')[0]
        .substring(1)
        .split('/')
      let result = null
      if (path[0] || path[0] === '') {
        this.menus.forEach(row => {
          if (row.path.toLowerCase() === ('/' + path[0]).toLowerCase()) {
            result = row.path
          }
        })
      }
      return result
    },
    submenus () {
      const result = []
      if (this.currentTopMenuId) {
        return this.menus.find(row => row.path === this.currentTopMenuId).children
      }
      return result
    }
  },
  methods: {
    async loadUserInfo () {
      await this.$currentUser.loadData()
      this.userInfo = this.$currentUser.info
      this.mediationTitle = this.$currentUser.mediationTitle
    },
    findFirstMenuPath (menus) {
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].component) {
          return menus[i].path
        } else if (menus[i].children) {
          const path = this.findFirstMenuPath(menus[i].children)
          if (path) {
            return path
          }
        }
      }
      return null
    },
    goto (menu, isRoot) {
      if (menu.children && menu.children.length && !isRoot) {
        menu.expand = !menu.expand
        return false
      }
      const findLastMenu = (menu) => {
        if (menu.children && menu.children.length > 0) {
          return findLastMenu(menu.children[0])
        } else {
          return menu.path
        }
      }
      const path = findLastMenu(menu)
      if (path === this.currentMenuPath) return false
      this.$router.push(path)
    },
    async logout () {
      this.$currentUser.clear()
      this.$router.push({ path: '/login', query: { redirect: window.location.hash.substring(1) } })
    },
    async updateBadge () {
      const res = await this.$axios.get('/apply/status/count')
      this.badge.apply = res.data.result.apply
      this.badge.myApply = res.data.result.mine
    }
  }
}
</script>
<style lang="less" scoped>
.ft-frame {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #f7f7fb;
    position: relative;
    .header {
        background: linear-gradient(270deg,#3f61f0 1%, #3350cb);
        display: flex;
        flex-direction: row;
        padding: 5px;
        box-sizing: border-box;
        .logo {
            background-image: url(@/assets/logo-white.png);
            height: 40px;
            width: 158px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            flex: 0 0 133px;
            margin-top: 6px;
        }
        .nav {
            flex: 1 auto;
            margin-left: 40px;
            display: flex;
            flex-direction: row;
            .nav-but {
                height: 50px;
                width: 100px;
                flex: 0 100px;
                background-color: transparent;
                border-radius: 4px;
                text-align: center;
                line-height: 50px;
                color: #fff;
                margin-right: 10px;
                cursor: pointer;
                &:hover {
                    background: #2135aa;
                }
                &.active {
                    background: #2135aa;
                }
            }
        }
        .tools {
            flex: 0 100px;
            display: flex;
            flex-direction: row;
            color: #fff;
            .user-info {
              display: flex;
              flex-direction: row;
              cursor: pointer;
            }
            .tools-btn {
                text-align: center;
                line-height: 50px;
                margin-right: 10px;
                .helper {
                  font-size: 20px;
                  display: inline-block;
                  vertical-align: middle;
                  height: 30px;
                  width: 30px;
                  border-radius: 50%;
                  line-height: 30px;
                  text-align: center;
                  cursor:pointer;
                  &:hover {
                    background-color: #5572EE;
                  }
                  i {

                    font-size:20px;
                    margin-left: 4px;
                  }
                }
                .message {
                    font-size: 22px;
                    vertical-align: middle;
                }
                .split {
                    display: inline-block;
                    width: 1px;
                    height: 20px;
                    background-color: #fff;
                    margin-left: 10px;
                    vertical-align: middle;
                }
                .avater {
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-top: -3px;
                }
                .company-name {
                  display: inline-block;
                  white-space: nowrap;
                  background-color:#5876EB;
                  height: 32px;
                  line-height: 32px;
                  padding: 0px 10px;
                  cursor: pointer;
                }

            }
        }
    }
    .main {
        flex: 1 auto;
        display: flex;
        flex-direction: row;
        // height: 100%;
        overflow: hidden;
        .menus {
            padding-top: 10px;
            background-color: #fff;
            flex: 0 0 200px;
            border-right: 1px solid #e6e6e6;
            overflow-y: auto;
            .menu-item {
                user-select: none;
                cursor: pointer;
                margin-top: 10px;
                font-size: 14px;
                color: #2a2b2f;
                padding: 10px 10px 10px 20px;
                display: flex;
                flex-direction: row;
                font-weight: 400;
                border-right: 3px solid transparent;
                &:hover {
                    background: #F0F1F6;
                    border-right: 3px solid #3350cb;
                    .label {
                        font-weight: 600;
                        color: #3350cb;
                    }
                }
                &.active {
                    background: #F0F1F6;
                    border-right: 3px solid #3350cb;
                    .label {
                        font-weight: 600;
                        color: #3350cb;
                    }
                }
                .icon {
                    flex: 0 20px;
                    line-height: 20px;
                    cursor: pointer;
                }
                .label {
                    flex: 1 auto;
                    line-height: 20px;
                    cursor: pointer;
                    font-size: 14px;
                }
                .expand {
                    flex: 0 20px;
                    line-height: 20px;
                    cursor: pointer;
                }
                .menu-item-icon {
                  font-size: 16px;
                  margin-right: 5px;
                  // margin-top: 3px;
                  line-height: 20px;
                }
                .badge {
                  background-color: #f56c6c;
                  border-radius: 10px;
                  color: #fff;
                  display: inline-block;
                  font-size: 12px;
                  height: 18px;
                  line-height: 18px;
                  padding: 0 6px;
                  text-align: center;
                  white-space: nowrap;
                  border: 1px solid #fff;
                  &.zero {
                    background-color: #797979;
                  }
                }
            }
        }
        .content {
            flex: 1 auto;
            background-color: #f7f7fb;
            padding: 20px;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            .tip {
              background: #fef1f0;
              border: 1px solid #ffb9b5;
              border-radius: 2px;
              font-size: 14px;
              font-weight: 400;
              text-align: left;
              color: #2a2b2f;
              line-height: 20px;
              padding: 10px;
              margin-bottom: 20px;
              i {
                color: #ff2b2b;
                margin-right: 15px;
              }
            }
            .inside {
              flex: 1 auto;
            }

        }
    }
}
</style>
<style lang="less">
.frame-popup-menu {
    padding: 0px !important;
    width: 310px !important;
    .top-menus {
        padding: 5px;
        .frame-popup-menu-info {
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          .avater {
            margin-top: 40px;
            img {
              width: 64px;
              height: 64px;
              border-radius: 32px;
            }
          }
          .name {
            margin-top: 8px;
            font-size: 18px;
            font-weight: 600;
            color: #2a2b2f;
            text-align: center;
            line-height: 22px;
          }
          .company {
            margin-top: 8px;
            margin-bottom: 25px;
            font-size: 14px;
            text-align: center;
            color: #9597a1;
            line-height: 22px;
          }
        }
        .frame-popup-menu-item {
          font-size: 14px;
          font-weight: 400;
          color: #2a2b2f;
          margin: 0px 0px;
          padding: 14px 24px;
          cursor: pointer;
          border-top: 1px solid #e6e7ef;
          i {
            font-size: 14px;
            margin-right: 10px;
          }
          &:hover {
            background-color: #F0F1F6;
          }
        }
    }
}
</style>
