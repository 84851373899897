import axios from 'axios'
import router from '@/router'
import currentUser from '@/utils/current_user'
import { Message } from 'element-ui'

export function createInstance (baseUrl) {
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 10000
  })
  instance.interceptors.request.use((config) => {
    const token = currentUser.token
    if (token) {
      config.headers.accessToken = token
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  instance.interceptors.response.use(res => {
    if (res?.data?.code > 200 && res?.data?.message) {
      if (res?.data?.code === 401) {
        router.push('/login')
        Message.error('登录已失效，请重新登录')
        return Promise.reject(new Error('' + res?.data?.message))
      } else {
        Message.error('' + res?.data?.message)
        return Promise.reject(new Error('' + res?.data?.message))
      }
    } else {
      return Promise.resolve(res)
    }
  })
  return instance
}
