export default {
  '': '',
  'Sign in': 'Sign in',
  'Phone Login': 'Phone Login',
  'Email Login': 'Email Login',
  'I have read and agree to the': 'I have read and agree to the',
  'Privacy Policy': 'Privacy Policy',
  and: 'and',
  'Terms of Use': 'Terms of Use',
  Login: 'Login',
  'Mobile number': 'Mobile number',
  'Verification code': 'Verification code',
  'Email address': 'Email address',
  'Please enter a valid phone number!': 'Please enter a valid phone number!',
  'Please enter a valid email address!': 'Please enter a valid email address!',
  'Please enter your phone number!': 'Please enter your phone number!',
  'Please enter your email address!': 'Please enter your email address!',
  'Please enter the verification code!': 'Please enter the verification code!',
  'Please accept the privacy policy and user agreement!': 'Please accept the privacy policy and user agreement!',
  'Incorrect verification code, please check the code!': 'Incorrect verification code, please check the code!',
  'The verification code has expired, please retrieve a new one!': 'The verification code has expired, please retrieve a new one!',
  'The verification code has been used, please retrieve a new one!': 'The verification code has been used, please retrieve a new one!',
  'Login failed, please try again.': 'Login failed, please try again.',
  SEND: 'SEND',
  ' ': ' ',
  'The room does not exist, please confirm the room number or create a room!': 'The room does not exist, please confirm the room number or create a room!',
  'Log out': 'Log out',
  'Edit profile': 'Edit profile',
  'User Name': 'User Name',
  'Please input user name': 'Please input user name',
  'Username length should be greater than 0': 'Username length should be greater than 0',
  Save: 'Save',
  Camera: 'Camera',
  Mic: 'Mic',
  'Off Camera': 'Off Camera',
  'Mic settings': 'Mic settings',
  Output: 'Output',
  Speaker: 'Speaker',
  Test: 'Test',
  Stop: 'Stop',
  Preview: 'Preview',
  Mirror: 'Mirror',
  Resolution: 'Resolution',
  'More Camera Settings': 'More Camera Settings',
  'Join the room ?': 'Join the room ?',
  'You are invited to room ': 'You are invited to room ',
  Room: 'Room',
  Join: 'Join',
  Sure: 'Sure',
  'New Room': 'New Room',
  'Room Type': 'Room Type',
  'Your Name': 'Your Name',
  'Grid view': 'Grid view',
  'Speaker view': 'Speaker view',
  'Turn on the microphone': 'Turn on the microphone',
  'Turn on the speaker': 'Turn on the speaker',
  'Turn on the video': 'Turn on the video',
  'Free Speech Room': 'Free Speech Room',
  'Raise Hand Room': 'Raise Hand Room',
  'Join Room': 'Join Room',
  'Enter room ID': 'Enter room ID',
  'video conferencing': ' \'s video conferencing',
  'Room ID': 'Room ID',
  'Low Definition': 'Low Definition',
  'Standard Definition': 'Standard Definition',
  'High Definition': 'High Definition',
  'Super Definition': 'Super Definition',
  'End sharing': 'End sharing',
  'Stop sharing?': 'Stop sharing?',
  'Stop sharing': 'Stop sharing',
  Sharing: 'Sharing',
  'Safety Reminder': '安全提醒',
  'Share screen': 'Share screen',
  'Others will no longer see your screen after you stop sharing. Are you sure you want to stop?': 'Others will no longer see your screen after you stop sharing. Are you sure you want to stop?',
  'Others will no longer see your screen after you stop sharing.': 'Others will no longer see your screen after you stop sharing.',
  'You currently do not have sharing permission, please raise your hand to apply for sharing permission first': 'You currently do not have sharing permission, please raise your hand to apply for sharing permission first',
  Host: 'Host',
  Me: 'Me',
  me: 'me',
  More: 'More',
  'Raise hand': 'Raise hand',
  Agree: 'Agree',
  Chat: 'Chat',
  Cancel: 'Cancel',
  Exit: 'Exit',
  'Full screen': 'Full screen',
  Members: 'Members',
  Invite: 'Invite',
  Settings: 'Settings',
  EndPC: 'End',
  EndH5: 'End',
  'You are currently the room host, please select the appropriate action.If you select "Leave Room", the room will not be dissolved and you will need to appoint a new host.': 'You are currently the room host, please select the appropriate action.If you select "Leave Room", the room will not be dissolved and you will need to appoint a new host.',
  'If you do not want to end the meeting, please designate a new host before leaving the meeting.': 'If you do not want to end the meeting, please designate a new host before leaving the meeting.',
  'Are you sure you want to leave this room?': 'Are you sure you want to leave this room?',
  'New host': 'New host',
  'Transfer and leave': 'Transfer and leave',
  'Leave room?': 'Leave room?',
  'Select a new host': 'Select a new host',
  'Appoint a new host': 'Appoint a new host',
  'The host closed the room.': 'The host closed the room.',
  Note: 'Note',
  Confirm: 'Confirm',
  Leave: 'Leave',
  Dismiss: 'Dismiss',
  'Moderator changed to ': 'Moderator changed to ',
  'Muted by the moderator': 'Muted by the moderator',
  'Type a message': 'Type a message',
  Send: 'Send',
  'Failed to send the message': 'Failed to send the message',
  'Applying for the stage': 'Applying for the stage',
  Check: 'Check',
  'Disable all audios': 'Disable all audios',
  'Disable all videos': 'Disable all videos',
  'Member List': 'Member List',
  'All mute': 'All mute',
  'All stop video': 'All stop video',
  'Lift all mute': 'Lift all mute',
  'Should we turn on the video for everyone': 'Should we turn on the video for everyone',
  'Can you lift all mute': 'Can you lift all mute',
  'Lift stop all video': 'Lift stop all video',
  Unmute: 'Unmute',
  'Cancel Unmute': 'Cancel Unmute',
  Mute: 'Mute',
  members: 'members',
  'Failed to get chat message': 'Failed to get chat message',
  'Agree to the stage': 'Agree to the stage',
  'Cancel stage': 'Cancel stage',
  'Invite stage': 'Invite stage',
  'Refuse stage': 'Refuse stage',
  'Step down': 'Step down',
  'Step down(Master)': 'Step down',
  'Enable video': 'Enable video',
  'Disable video': 'Disable video',
  'Enable chat': 'Enable chat',
  'Disable chat': 'Disable chat',
  'Kick out': 'Kick out',
  'is sharing their screen': 'is sharing their screen',
  'Apply to stage application': 'Apply to stage application',
  Reject: 'Reject',
  'Reject All': 'Reject All',
  'Please raise your hand to apply': 'Please raise your hand to apply',
  'The host invites you to speak on stage': 'The host invites you to speak on stage',
  'After agreeing to go on stage, you can turn on the camera and microphone. Do you agree to go on stage?': 'After agreeing to go on stage, you can turn on the camera and microphone. Do you agree to go on stage?',
  'Hand down': 'Hand down',
  'The host has approved your application': 'The host has approved your application',
  'The host has rejected your application for the stage': 'The host has rejected your application for the stage',
  'You have been invited by the host to step down, please raise your hand if you need to speak': 'You have been invited by the host to step down, please raise your hand if you need to speak',
  'Select a screen/window': 'Select a screen/window',
  Screen: 'Screen',
  Window: 'Window',
  Share: 'Share',
  'Continue sharing': 'Continue sharing',
  'Select a screen or window first': 'Select a screen or window first',
  Grid: 'Grid',
  Tips: 'Tips',
  'Gallery on right': 'Gallery on right',
  'Gallery at top': 'Gallery at top',
  'Network ': 'Network ',
  'Latency: ': 'Latency: ',
  'Frame rate: ': 'Frame rate: ',
  'Bitrate: ': 'Bitrate: ',
  Unknown: 'Unknown',
  Excellent: 'Excellent',
  Good: 'Good',
  Fair: 'Fair',
  Poor: 'Poor',
  'Very poor': 'Very poor',
  Disconnected: 'Disconnected',
  'Share the room ID or invite link': 'Share the room ID or invite link',
  'Invite by room number': 'Invite by room number',
  'Invite via room link': 'Invite via room link',
  'Room Link': 'Room Link',
  'You can share the room number or link to invite more people to join the room.': 'You can share the room number or link to invite more people to join the room.',
  'If you have any questions, please feel free to join our QQ group or send an email': 'If you have any questions, please feel free to join our QQ group or send an email',
  'Room QR code': 'Room QR code',
  'Search for conference attendees': 'Search for conference attendees',
  'Invite via client scheme': 'Invite via client scheme',
  'All current and new members will be muted.': 'All current and new members will be muted',
  'All current and new members will turn off their videos.': 'All current and new members will turn off their videos',
  'No relevant user found.': 'No relevant user found.',
  'Contact us': 'Contact us',
  'Member management': 'Member management',
  'Join our QQ group chat or email us.': 'Join our QQ group chat or email us.',
  'group chat': 'group chat',
  Email: 'Email',
  'Audio settings': 'Audio settings',
  'Camera settings': 'Camera settings',
  'Copied successfully': 'Copied successfully',
  'accepted the invitation to the stage': 'accepted the invitation to the stage',
  'declined the invitation to the stage': 'declined the invitation to the stage',
  'The host has muted all': 'The host has muted all',
  'The host has unmuted all': 'The host has unmuted all',
  'The host has turned off your microphone': 'The host has turned off your microphone',
  'The host invites you to turn on the microphone': 'The host invites you to turn on the microphone',
  'The host has turned on the ban on all paintings': 'The host has turned on the ban on all paintings',
  'The host has lifted the ban on all paintings': 'The host has lifted the ban on all paintings',
  'The host has turned on the ban on all chat': 'The host has turned on the ban on all chat',
  'The host has lifted the ban on all chat': 'The host has lifted the ban on all chat',
  'The host has turned off your camera': 'The host has turned off your camera',
  'The host invites you to turn on the camera': 'The host invites you to turn on the camera',
  'The host has turned off your screen sharing': 'The host has turned off your screen sharing',
  'Turn on the camera': 'Turn on the camera',
  'Keep it closed': 'Keep it closed',
  'You have been banned from text chat by the host': 'You have been banned from text chat by the host',
  'You are allowed to text chat by the host': 'You are allowed to text chat by the host',
  'kicked out of the room by the host': 'kicked out of the room by the host',
  'kicked out of the room by other device': 'kicked out of the room by other device',
  'kicked out of the room by serve': 'kicked out of the room by serve',
  'Reject on Stage failed, please retry': 'Reject {userName} on Stage failed, please retry',
  'Has been fully muted and cannot open the microphone': 'Has been fully muted and cannot open the microphone',
  'Has been muted by the host and cannot open the microphone': 'Has been muted by the host and cannot open the microphone',
  'To apply to speak in the room, please raise your hand first to apply for the microphone': 'To apply to speak in the room, please raise your hand first to apply for the microphone',
  'Has been full static painting, can not open the video': 'Has been full static painting, can not open the video',
  'It has been stilled by the host and cannot open the video': 'It has been stilled by the host and cannot open the video',
  'Failed to enter the room.': 'Failed to enter the room.',
  'Has been full static painting, can not share your screen': 'Has been full static painting, can not share your screen.',
  'Microphone not detected on current device': 'Microphone not detected on current device',
  'Camera not detected on current device': 'Camera not detected on current device',
  'Camera And Microphone not detected on current device': 'Camera And Microphone not detected on current device',
  'Another user is currently sharing the screen, screen sharing is not possible.': 'Another user is currently sharing the screen, screen sharing is not possible.',
  'This action causes the room to be disbanded, does it continue?': 'This action causes the room to be disbanded, does it continue?',
  'This action causes the room to be exited, does it continue?': 'This action causes the room to be exited, does it continue?',
  'Sharing screens may lead to the leakage of private information such as SMS verification codes and passwords, resulting in financial losses. Please be vigilant against various forms of fraud.': 'Sharing screens may lead to the leakage of private information such as SMS verification codes and passwords, resulting in financial losses. Please be vigilant against various forms of fraud.'
}
