// 系统管理

const accountInfo = () => import('@/webapp/system/account-info.vue')
const accountSecure = () => import('@/webapp/system/account-secure.vue')
const memberRole = () => import('@/webapp/system/member-role.vue')
const memberList = () => import('@/webapp/system/member-list.vue')
const menusList = () => import('@/webapp/system/menus-list.vue')
const systemMenuList = [
  {
    path: '/system',
    meta: {
      icon: '',
      name: 'system',
      title: '系统管理',
      apis: [
      ]
    },
    children: [
      {
        path: '/account',
        meta: {
          id: '',
          icon: 'iconfont icon-zhanghaoshezhiicon',
          iconColor: '#527aee',
          name: 'account',
          title: '帐号设置',
          apis: [
          ]
        },
        children: [
          {
            path: '/info',
            meta: {
              id: '',
              icon: '',
              name: 'info',
              title: '个人信息'
            },
            component: accountInfo
          },
          {
            path: '/secure',
            meta: {
              id: '',
              icon: '',
              name: 'secure',
              title: '帐号安全',
              apis: [
              ]
            },
            component: accountSecure
          }
        ]
      },
      {
        path: '/member',
        meta: {
          icon: 'iconfont icon-yuangongzhongxinicon',
          iconColor: '#45acf6',
          name: 'member',
          title: '调解成员',
          code: 'system:member'
        },
        children: [
          {
            path: '/role',
            meta: {
              icon: '',
              name: 'role',
              title: '角色管理',
              code: 'system:member:role'
            },
            component: memberRole
          },
          {
            path: '/list',
            meta: {
              icon: '',
              name: 'member',
              title: '调解员管理',
              code: 'system:member:list'
            },
            component: memberList
          }
        ]
      }
    ]
  }
]

if (process.env.NODE_ENV === 'development') {
  systemMenuList[0].children.push(
    {
      path: '/menus',
      meta: {
        iocn: '',
        name: 'menus',
        title: '菜单管理',
        code: ''
      },
      component: menusList
    }

  )
}

export default systemMenuList
