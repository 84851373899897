// 首页
export default [
  {
    path: '/home',
    meta: {
      icon: '',
      name: 'home',
      title: '首页',
      code: 'home'
    },
    children: [
      {
        path: '/mediation-apply',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'mediation-center',
          title: '调解申请管理',
          code: 'home:apply'
        },
        children: [
          {
            path: '/apply',
            meta: {
              name: 'apply',
              title: '申请列表',
              badge: 'apply',
              code: 'home:apply:list'
            },

            component: () => import('@/webapp/home/apply-list.vue')
          },
          {
            path: '/my',
            meta: {
              name: 'my',
              title: '我的受理',
              badge: 'myApply',
              code: 'home:apply:my'
            },

            component: () => import('@/webapp/home/apply.vue')
          },
          {
            path: '/all',
            meta: {
              name: 'all',
              title: '全部申请',
              code: 'home:apply:all'
            },
            component: () => import('@/webapp/home/apply-all.vue')
          }
        ]
      },
      {
        path: '/online',
        meta: {
          icon: 'iconfont icon-menleiguanliicon',
          iconColor: '#4c7cff',
          name: 'online',
          title: '在线调解',
          code: 'home:online'
        },
        children: [
          {
            path: '/room',
            meta: {
              name: 'room',
              title: '调解室',
              code: 'home:online:room'
            },
            component: () => import('@/webapp/home/online/room.vue')
          },
          {
            path: '/record',
            meta: {
              name: 'record',
              title: '调解记录',
              code: 'home:online:record'
            },
            component: () => import('@/webapp/home/online/record.vue')
          }
        ]
      }
    ]

  }
]
