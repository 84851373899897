import { city, province, area } from './area'
export const AREA_TREE = province.map(row => {
  let v = false
  let cityList = city.filter(r => r.p === row.p)
  if (cityList.length === 0) {
    v = true
    cityList = area.filter(rr => rr.p === row.p)
  }
  return {
    label: row.n,
    value: row.c,
    children: cityList.map(r => {
      return {
        label: r.n,
        value: r.c,
        children: v
          ? null
          : area.filter(rr => rr.p === r.p && rr.t === r.t).map(rr => {
            return {
              label: rr.n,
              value: r.c
            }
          })
      }
    })
  }
})

export const DISPUTE_STATUS = [null, '合同纠纷', '债务纠纷', '保险纠纷', '公司纠纷', '知识产权纠纷']

export const MEDIATION_ROOM_STATUS = ['未开始', '调解中']

export const APPLY_TYPE = [
  {
    label: '合同纠纷',
    desc: '合同履约、违约、解除',
    value: 1
  },
  {
    label: '债务纠纷',
    desc: '欠款、逾期、违约担保',
    value: 2
  },
  {
    label: '保险纠纷',
    desc: '车险、意外险、人寿保险',
    value: 3
  },
  {
    label: '公司纠纷',
    desc: '股权纠纷、并购、公司治理',
    value: 4
  },
  {
    label: '知识产权纠纷',
    desc: '专利、商标、著作权侵权',
    value: 5
  }
]

export const APPLY_IDENTITY = [
  {
    label: '个人',
    value: 1
  },
  {
    label: '企业',
    value: 2
  }
]

export const ENTRUST_TYPE = [null, '个人', '企业']

export const APPLY_STATUS = ['待受理', '受理中', '申请通过', '申请驳回']

export const EDUCATION_TYPE = ['高中及以下', '大专', '本科', '研究生及以上']
