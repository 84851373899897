import Vue from 'vue'
import ElementUI, { Message } from 'element-ui'
import '@/assets/css/theme/index.css'
import '@/assets/css/common.less'
import App from './App.vue'
import router from './router'
import filters from '@/utils/filter'
import axios from 'axios'
import ftComponent from '@/components/index.js'
import { createPinia, PiniaVuePlugin } from 'pinia'
import i18n from './components/TUIRoom/locales/'
import currentUser from '@/utils/current_user'
import { createInstance } from '@/utils/axios'

Vue.use(i18n as any)

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.prototype.$axios = createInstance('/mediator/api/v1')

Vue.prototype.$axiosOne = createInstance('/mediation/api/v1')

Vue.prototype.$axiosTrtc = createInstance('/trtc/v1')

Vue.prototype.$currentUser = currentUser
Vue.config.productionTip = false
Vue.use(filters)
Vue.use(ElementUI, { size: 'small' })
Vue.use(ftComponent)

Vue.mixin({
  methods: {
    getPermissionList () {
      return (this as any).$currentUser.permission
    },
    hasPermission (code) {
      const permission = (this as any).getPermissionList() as string[]
      return permission.some(row => {
        return row.startsWith(code)
      })
    }
  }
})
new Vue({
  pinia,
  router,
  render: h => h(App)
}).$mount('#app')
