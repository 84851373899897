<template>
  <el-upload
    class="avatar-uploader"
    :drag="drag"
    :list-type="listType"
    :limit="limit"
    :accept="accept"
    :action="updateUrl"
    :headers="headers"
    :http-request="uploadFile"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
    ref="uploader"
  >
    <template v-if="!$slots.default">
      <template v-if="value">
        <img :src="value" class="avatar" :style="{ width, height }"/>
        <div class="operation">
          <!-- <i class="el-icon-zoom-in" @click.stop="handlerPreview"></i> -->
          <i class="el-icon-delete" @click.stop="handlerDelete"></i>
        </div>
      </template>
      <template v-else>
        <i class="el-icon-camera-solid avatar-uploader-icon" :style="{ width, height, lineHeight: height + ' !important' }"></i>
      </template>
    </template>
    <template v-else>
      <slot name="default" />
    </template>
    <FtCroppr :src="cropperSrc" :min-crop-box-width="200" :dialog-width="700" :height="500" :original-size="true" :aspectRatio="aspectRatio"  @crop="uploadFile" :bindDialog="{ 'append-to-body': true }" :visible.sync="cropperVisable"></FtCroppr>
  </el-upload>
</template>
<script>
import FtCroppr from '@/components/ft-cropper.vue'
import { uploadFile } from '@/utils/cos'
export default {
  components: {
    FtCroppr
  },
  data () {
    return {
      cropperSrc: null,
      cropperVisable: false,
      updateUrl: '/operation/v1/common/file',
      headers: {
        token: ''
      }

    }
  },
  beforeCreate () {},
  props: {
    accept: {
      type: String,
      default () {
        return null
      }
    },
    crop: {
      type: Boolean,
      default () {
        return false
      }
    },
    aspectRatio: {
      type: Number,
      default () {
        return 1 / 1
      }
    },
    width: {
      type: String,
      default () {
        return '178px'
      }
    },
    height: {
      type: String,
      default () {
        return '178px'
      }
    },
    listType: {
      type: String,
      default () {
        return 'picture'
      }
    },
    limit: {
      type: Number,
      default () {
        return 1
      }
    },
    value: {
      type: String,
      default () {
        return ''
      }
    },
    drag: {
      type: Boolean,
      default () {
        return false
      }
    },
    miniType: {
      type: Array,
      default () {
        return ['image/jpeg', 'image/png', 'image/webp']
      }
    },
    size: {
      type: Number,
      default () {
        return 2
      }
    }
  },
  methods: {
    handlerPreview () {
      // this.$refs.uploader.onPreview()
    },
    handlerDelete () {
      this.$emit('input', null)
      this.$refs.uploader.clearFiles()
    },
    handleAvatarSuccess (res, file) {
      this.$emit('input', res.url)
    },
    beforeAvatarUpload (file) {
      const isAccpet = this.miniType.includes(file.type)
      const isLt2M = file.size / 1024 / 1024 < this.size
      if (!isAccpet) {
        this.$message.error('上传格式不正确!')
      }
      if (!isLt2M) {
        this.$message.error(`上传大小不能超过 ${this.size}MB!`)
      }
      const result = isAccpet && isLt2M
      if (this.crop) {
        this.cropperSrc = file
        this.cropperVisable = true
        return false
      }
      return result
    },
    async uploadFile (fileObject) {
      const crop = this.crop
      let file = null
      if (this.crop) {
        const canvas = fileObject
        file = await new Promise(resolve => {
          canvas.toBlob(blob => {
            const file = new File([blob], `crop_${new Date().getTime()}.png`, { type: 'image/png' })
            resolve(file)
          })
        })
      } else {
        file = fileObject.file
      }
      if (!file) return
      const url = await uploadFile(file, {
        onProgress (progressData) {
          this.$emit('progress', progressData)
        }
      })
      if (crop) {
        this.$emit('input', url)
      } else {
        return { url }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.avatar-uploader {
  /deep/ .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
      .operation {
        opacity: 1;
      }
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
    .avatar {
      width: 178px;
      height: 178px;
      display: block;
    }
    .operation {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      color: #fff;
      opacity: 0;
      font-size: 20px;
      background-color: rgba(0,0,0,0.5);
      transition: opacity .3s;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      // i {
      //   margin-right: 10px;
      // }
    }
  }

}
</style>
